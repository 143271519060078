import Immutable from "immutable";

import { types } from "../actions/exerciseActions";

export const INITIAL_QUESTION_STATE = Immutable.fromJS({});
export function questionReducer(state = INITIAL_QUESTION_STATE, action = {}) {
  switch (action.type) {
    case types.EXERCISE.QUESTION.SUCCESS:
      return action.payload.question;
    default:
      return state;
  }
}

export const INITIAL_ANSWER_STATE = Immutable.fromJS({ answer: null });
export function answerReducer(state = INITIAL_ANSWER_STATE, action = {}) {
  switch (action.type) {
    case types.EXERCISE.QUESTION.SUCCESS:
      return Immutable.fromJS({
        answer: null,
        isCorrect: null,
      });
    /*
    case types.EXERCISE.ANSWER.SUBMIT:
      return Immutable.fromJS({
        answer: action.payload.answer,
        isCorrect: null,
      });
    */
    case types.EXERCISE.ANSWER.CHECKED.CORRECT:
      return Immutable.fromJS({
        answer: action.payload.answer,
        isCorrect: true,
      });
    case types.EXERCISE.ANSWER.CHECKED.WRONG:
      return Immutable.fromJS({
        answer: action.payload.answer,
        isCorrect: false,
      });
    default:
      return state;
  }
}

export const INITIAL_ANSWER_STATS_STATE = Immutable.fromJS({
  exerciseId: "notesOnFretboard",
  count: 0,
  list: [],
});
export function answerStatsReducer(
  state = INITIAL_ANSWER_STATS_STATE,
  action = {}
) {
  switch (action.type) {
    case types.EXERCISE.ANSWER_STATS.UPDATED_BY_CLIENT:
      return action.payload.answerStats;
    default:
      return state;
  }
}

export const INITIAL_SETTINGS_STATE = Immutable.fromJS({
  exerciseId: "notesOnFretboard",
  frets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  strings: [6, 5, 4, 3, 2, 1],
});
export function settingsReducer(state = INITIAL_SETTINGS_STATE, action = {}) {
  switch (action.type) {
    case types.EXERCISE.SETTINGS.UPDATED_BY_CLIENT:
    case types.EXERCISE.SETTINGS.SUCCESS:
      return action.payload.settings;
    default:
      return state;
  }
}

import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { FretboardNote } from "../drills/shared/MusicLib";

const gridColor = "#444";
const inlayColor = "#ccc";
const noteNeutralColor = "#000";
const noteCorrectColor = "#449d44"; // bootstrap dark green (primary button hover)
//const noteIncorrectColor = "#c9302c"; // bootstrap dark red (danger button hover
const strokeWidth = 2.75;
const fretStrokeWidth = 2.75;
const zeroFretStrokeWidth = 8.25;

const FretboardSvg = styled.svg`
  overflow: visible;
`;
FretboardSvg.displayName = "FretboardSvg";

const NoteCircle = styled.circle`
  stroke: ${(props) =>
    props.type === "correct" ? noteCorrectColor : noteNeutralColor};
  fill: ${(props) =>
    props.type === "correct" ? noteCorrectColor : noteNeutralColor};
`;
NoteCircle.displayName = "NoteCircle";
NoteCircle.propTypes = {
  type: PropTypes.string,
};

const InlayGroup = styled.g`
  stroke: ${inlayColor};
  fill: ${inlayColor};
`;
InlayGroup.displayName = "InlayGroup";

const FretGroup = styled.g`
  stroke: ${gridColor};
  stroke-width: ${strokeWidth};
`;
FretGroup.displayName = "FretGroup";

const StringGroup = styled.g`
  stroke: ${gridColor};
`;
StringGroup.displayName = "StringGroup";

function times(n: number): number[] {
  return "x"
    .repeat(n)
    .split("")
    .map((_, i) => i);
}

interface FretboardProps {
  notes?: FretboardNote[];
}
export default function Fretboard(props: FretboardProps) {
  const notes = props.notes || [];

  const marginLeft = 50;
  const marginTop = 20;
  const marginRight = 10;
  const marginBottom = 20;

  const numberOfStrings = 6;
  const numberOfFrets = 12; // number of frets excluding zero fret
  const visibleFretboardLength = 1000; // relative value used in viewbox
  const stringSpacing = 45;
  const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2;
  const inlayRadius = stringSpacing / GOLDEN_RATIO / GOLDEN_RATIO / 2;
  const noteRadius = stringSpacing / 2.25;
  const fretboardHeight = (numberOfStrings - 1) * stringSpacing;
  const viewBoxWidth = visibleFretboardLength + marginLeft + marginRight;
  const viewBoxHeight = fretboardHeight + marginTop + marginBottom;

  const strings = times(numberOfStrings).map(function (stringIndex) {
    const yPosition = stringIndex * stringSpacing;
    const stringWidthMultipliers = [10, 13, 17, 26, 36, 46];
    const stringStrokeWidth =
      numberOfStrings === 6
        ? (stringWidthMultipliers[stringIndex] / 20) * strokeWidth
        : 1.25 *
          (strokeWidth +
            ((stringIndex - numberOfStrings / 2) / numberOfStrings) *
              strokeWidth);

    const correctionForZeroFretWidth = (-1 * zeroFretStrokeWidth) / 2;
    const xStart = marginLeft + correctionForZeroFretWidth;
    const correctionForLastFretWidth = fretStrokeWidth / 2;
    const xEnd =
      marginLeft + visibleFretboardLength + correctionForLastFretWidth;

    return (
      <line
        key={"string_" + stringIndex}
        strokeWidth={stringStrokeWidth}
        x1={xStart}
        y1={marginTop + yPosition}
        x2={xEnd}
        y2={marginTop + yPosition}
      />
    );
  });

  function fretXPositionInViewBox(fretIndex: number) {
    // Length of the fretboard if we drew the whole fretboard from nut to
    // bridge. We are actually drawing only N number of frets.
    const fullFretboardLength =
      Math.pow(2, numberOfFrets / 12) * visibleFretboardLength;
    // Calculate fret position, see http://www.liutaiomottola.com/formulae/fret.htm
    const distanceFromNutOnFullFretboard =
      fullFretboardLength - fullFretboardLength / Math.pow(2, fretIndex / 12);
    const lastFretOnFullFretboard =
      fullFretboardLength -
      fullFretboardLength / Math.pow(2, numberOfFrets / 12);
    // Transform the full fretboard coordinate to the visible fretboard in view box
    const distanceFromNutOnVisibleFretboard =
      (distanceFromNutOnFullFretboard / lastFretOnFullFretboard) *
      visibleFretboardLength;
    return marginLeft + distanceFromNutOnVisibleFretboard;
  }

  // Include zero fret, thus + 1
  const frets = times(numberOfFrets + 1).map(function (fretIndex) {
    const xPosition = fretXPositionInViewBox(fretIndex);
    return (
      <line
        key={"fret_" + fretIndex}
        strokeWidth={fretIndex === 0 ? zeroFretStrokeWidth : fretStrokeWidth}
        x1={xPosition}
        y1={marginTop}
        x2={xPosition}
        y2={marginTop + fretboardHeight}
      />
    );
  });

  const inlays = times(numberOfFrets + 1)
    .filter((fretIndex) =>
      [1, 3, 5, 7, 9, 12, 15, 17, 19, 21, 24].includes(fretIndex)
    )
    .map(function (fretIndex) {
      if (![12, 24].includes(fretIndex)) {
        const fretX = fretXPositionInViewBox(fretIndex);
        const previousFretX = fretXPositionInViewBox(fretIndex - 1);
        const inlayX = (fretX + previousFretX) / 2;
        const inlayY = marginTop + fretboardHeight / 2;
        return (
          <circle
            key={"inlay_" + fretIndex}
            cx={inlayX}
            cy={inlayY}
            r={inlayRadius}
          />
        );
      } else {
        const fretX = fretXPositionInViewBox(fretIndex);
        const previousFretX = fretXPositionInViewBox(fretIndex - 1);
        const inlayX = (fretX + previousFretX) / 2;
        const inlay1Y = marginTop + fretboardHeight / 2 - stringSpacing;
        const inlay2Y = marginTop + fretboardHeight / 2 + stringSpacing;
        return (
          <g key={"inlay_" + fretIndex}>
            <circle cx={inlayX} cy={inlay1Y} r={inlayRadius} />
            <circle cx={inlayX} cy={inlay2Y} r={inlayRadius} />
          </g>
        );
      }
    });

  function generateNoteCircle(note: FretboardNote) {
    const { string, fret, label } = note;
    const type = note.type || "default";

    let noteX;
    if (fret > 0) {
      const fretX = fretXPositionInViewBox(fret);
      const previousFretX = fretXPositionInViewBox(fret - 1);
      noteX = (fretX + previousFretX) / 2;
    } else if (fret === 0) {
      // zero fret is a special case, we wan"t it rather close to the zero fret
      noteX = fretXPositionInViewBox(0) - 1.5 * noteRadius;
    } else {
      console.warn(
        "fret has to be a non-negative integer. Value was " +
          fret +
          " (" +
          typeof fret +
          ")"
      );
    }
    const noteY = marginTop + (string - 1) * stringSpacing;

    return (
      <g key={"note_" + string + "_" + fret}>
        <NoteCircle cx={noteX} cy={noteY} r={noteRadius} type={type} />
        {label && (
          <text
            x={noteX}
            y={noteY}
            dy="11"
            textAnchor="middle"
            fontFamily="Arial, sans-serif"
            fontSize="30"
            fontWeight="bold"
            stroke="none"
            fill="#fff"
          >
            {label}
          </text>
        )}
      </g>
    );
  }

  const noteCircles = notes.map(generateNoteCircle);

  return (
    <FretboardSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    >
      <InlayGroup>{inlays}</InlayGroup>
      <FretGroup>{frets}</FretGroup>
      <StringGroup>{strings}</StringGroup>
      <g>{noteCircles}</g>
    </FretboardSvg>
  );
}

Fretboard.defaultProps = {
  notes: [],
};

// TODO rename to authenticationSagas

import firebase from "../util/firebase";
import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

import {
  types,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  syncUser,
} from "./actions/loginActions";
import reduxSagaFirebase from "./reduxSagaFirebase";

export function* loginSaga() {
  try {
    const googleAuthProvider = yield new firebase.auth.GoogleAuthProvider();
    yield call(reduxSagaFirebase().auth.signInWithPopup, googleAuthProvider);
    yield put(loginSuccess());
  } catch (error) {
    // TODO handle different types of errors. See src/types/firebaseTypes
    yield put(loginFailure(error));
  }
}

function* logoutSaga() {
  try {
    const data = yield call(reduxSagaFirebase().auth.signOut);
    yield put(logoutSuccess(data));
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

function* syncUserSaga() {
  try {
    // redux-saga Channel which emits every user change.
    const userChannel = yield call(reduxSagaFirebase().auth.channel);
    while (true) {
      const { error, user } = yield take(userChannel);
      if (error) {
        // TODO handle user sync error
        console.error(error);
      }
      yield put(syncUser(user));
    }
  } catch (error) {
    // TODO trigger some global error and tell user to refresh
    console.error("Error in syncUserSaga.", error);
  }
}

export default function* loginRootSaga() {
  yield fork(syncUserSaga);
  try {
    yield all([
      takeEvery(types.LOGIN.REQUEST, loginSaga),
      takeEvery(types.LOGOUT.REQUEST, logoutSaga),
    ]);
  } catch (error) {
    // TODO trigger some global error and tell user to refresh
    console.error("Error in loginRootSaga.", error);
  }
}

import Immutable from "immutable";

import { types } from "../actions/loginActions";

const initialState = Immutable.fromJS({
  loading: false,
  loggedIn: false,
  user: null,
});

export default function loginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGIN.REQUEST:
    case types.LOGOUT.REQUEST:
      return state.merge({
        loading: true,
      });
    case types.LOGIN.SUCCESS:
      return state.merge({
        loading: false,
        loggedIn: true,
      });
    case types.LOGIN.FAILURE:
      return state.merge({
        loading: false,
      });
    case types.LOGOUT.SUCCESS:
      return state.merge({
        loading: false,
        loggedIn: false,
      });
    case types.LOGOUT.FAILURE:
      return state.merge({
        loading: false,
      });
    case types.SYNC_USER:
      return state.merge({
        loggedIn: action.payload.user !== null,
        user: action.payload.user,
      });

    default:
      return state;
  }
}

import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 1rem;
  height: 1rem;
  vertical-align: baseline;

  & path {
    fill: ${props => props.theme.linkColor};
  }
`;

const ExternalLinkIcon = () => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M90 5H62a4 4 0 1 0 0 8h19L40 54a4 4 0 0 0 3 7 4 4 0 0 0 3-1L86 20v18a4 4 0 1 0 8 0V9c0-2-2-4-4-4z" />
    <path d="M76 47a4 4 0 0 0-4 4v33c0 2-1 3-3 3h-53c-2 0-3-1-3-3v-53c0-2 1-3 3-3h33a4 4 0 1 0 0-8H16c-6 0-11 5-11 11v53c0 6 5 11 11 11h53c6 0 11-5 11-11V51a4 4 0 0 0-4-4z" />
  </StyledSvg>
);
ExternalLinkIcon.displayName = "ExternalLinkIcon";

export default ExternalLinkIcon;

export default {
  frontpage: "/",
  chordProgressionDrill: "/chord-progression",
  contextual: "/contextual-ear-training",
  chordConstruction: "/chord-construction",
  fretboardTraining: "/guitar-fretboard-notes",
  fretboardInterval: "/guitar-fretboard-intervals",
  intervalTraining: "/interval-training",
  signIn: "/sign-in",
  profile: "/profile",
  contact: "/contact",
} as const;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

import Config from "../Config";

//if (process.env.NODE_ENV === "development") {
//  firebase.firestore.setLogLevel("debug");
//}

// NOTE: When you add anything here, make sure to add any relevant corresponding
// code into src/util/__mocks__/firebase.ts also.

const firebaseApp = firebase.initializeApp(Config.firebase);

const firebaseAnalytics = firebase.analytics();

const firebaseAuth = firebase.auth();

const firestore = firebase.firestore();

export default firebase;
export { firebaseApp, firebaseAnalytics, firebaseAuth, firestore };

import Immutable from "immutable";
import { buffers, channel, eventChannel } from "redux-saga";
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  join,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";

import {
  getAnswerStatsSaga,
  setAnswerStatsSaga,
  getSettingsSaga,
  setSettingsSaga,
} from "./firestoreSagas";
import { combineLatest } from "./sagaOperators";
import Config from "../Config";
import {
  types,
  loadQuestions,
  askNewQuestion,
  startExerciseSuccess,
  correctAnswer,
  wrongAnswer,
  answerStatsUpdatedByClient,
  answerStatsFetchedFromStore,
  submitAnswer,
  settingsFetchedFromStore,
} from "./actions/exerciseActions";
import { types as loginTypes } from "./actions/loginActions";
import {
  INITIAL_ANSWER_STATS_STATE,
  INITIAL_SETTINGS_STATE,
} from "./reducers/exerciseReducers";
import localStorage from "../util/localStorage";
// TODO rename types to actions or actionTypes everywhere

import Memo from "../memo/Memo";
import Questions from "../memo/Questions";

// Note that the channel contains answerStats objects, not action objects
// !!!!!!!!!!!!!!!
// TODO pass this as an argument instead of "global" export
export const questionsChannel = channel(buffers.sliding(1));
export const answerStatsChannel = channel(buffers.sliding(1));
export const settingsChannel = channel(buffers.sliding(1));

function* syncQuestionsChannel() {
  while (true) {
    try {
      const {
        payload: { questions },
      } = yield take(types.EXERCISE.QUESTIONS.SUCCESS);
      yield put(questionsChannel, questions);
    } catch (error) {
      console.error("Error in syncQuestionsChannel.", error);
    }
  }
}

function* syncAnswerStatsChannel() {
  while (true) {
    try {
      const {
        payload: { answerStats },
      } = yield take([
        types.EXERCISE.ANSWER_STATS.UPDATED_BY_CLIENT,
        types.EXERCISE.ANSWER_STATS.SUCCESS,
      ]);
      yield put(answerStatsChannel, answerStats);
    } catch (error) {
      console.error("Error in syncAnswerStatsChannel.", error);
    }
  }
}

function* syncSettingsChannel() {
  while (true) {
    try {
      const {
        payload: { settings },
      } = yield take([
        types.EXERCISE.SETTINGS.UPDATED_BY_CLIENT,
        types.EXERCISE.SETTINGS.SUCCESS,
      ]);
      yield put(settingsChannel, settings);
    } catch (error) {
      console.error("Error in syncSettingsChannel.", error);
    }
  }
}

function* startExerciseSaga() {
  try {
    yield take(types.EXERCISE.START.REQUEST);
    // TODO should we do something here
    yield put(startExerciseSuccess());
  } catch (error) {
    console.error("Error in startExerciseSaga.", error);
  }
}

// TODO start listening to keyboard on new question and stop/cancel listening
// on correctly answered question

function* gameLoopSaga() {
  // TODO handle login+logout+login
  // const syncUserTask = yield fork(take, loginTypes.SYNC_USER);
  // TODO const exerciseId = yield ...
  let user;
  try {
    const { syncUserAction } = yield all({
      startExercise: take(types.EXERCISE.START.SUCCESS),
      syncUserAction: take(loginTypes.SYNC_USER),
    });
    user = syncUserAction.payload.user;
  } catch (error) {
    console.error("Error in gameLoopSaga getting initial user.", error);
  }
  while (true) {
    const askQuestionsTask = yield fork(
      combineLatest,
      [questionsChannel, answerStatsChannel, settingsChannel],
      askQuestions
    );

    // TODO: put some loading screen while answerStats and settings are fetched
    yield fork(getAnswerStatsFromStoreOrInitialValue, user);
    yield fork(getSettingsFromStoreOrInitialValue, user);
    yield fork(getQuestionsFromStaticData);

    try {
      let {
        payload: { user: newUser },
      } = yield take(loginTypes.SYNC_USER);
      user = newUser;
    } catch (error) {
      console.error("Error in gameLoopSaga getting new user.", error);
    }

    try {
      yield cancel(askQuestionsTask);
    } catch (error) {
      console.error(
        "Error in gameLoopSaga cancelling askQuestionsTask after syncing user.",
        error
      );
    }
  }

  // TODO Put this loop in a separate saga and cancel it on user change (login/logout)
}

function* askQuestions([questions, answerStats, settings]) {
  try {
    const questionsWithAnswerStats = Questions.mergeQuestionsAndAnswerStats(
      questions,
      answerStats
    );

    // Get new question
    const question = Memo.heuristicAlgorithm({
      questionsWithAnswerStats,
      settings,
    });
    yield put(askNewQuestion(question));

    // Wait for correct answer and update stats
    const answerTimeTask = yield fork(answerTimeSaga);
    yield fork(checkAnswerSaga, question);
    // TODO Should we update stats also on wrong answers?
    const answer = yield take(types.EXERCISE.ANSWER.CHECKED.CORRECT);
    const answerTime = yield join(answerTimeTask);
    const updatedAnswerStats = yield call(
      Memo.updateAnswerStats,
      question,
      answer,
      answerTime,
      answerStats
    );

    // TODO is the best place for the delay?
    yield delay(Config.exercise.nextQuestionDelayInMs);
    yield put(answerStatsUpdatedByClient(updatedAnswerStats));
  } catch (error) {
    console.error("Error in askQuestions saga.", error);
  }
}

// TODO should take exerciseId as argument
function* getQuestionsFromStaticData() {
  const questions = yield Questions.NOTES_ON_FRETBOARD;
  try {
    yield put(loadQuestions(questions));
  } catch (error) {
    console.error("Error in getQuestionsFromStaticData.", error);
  }
}

// TODO pass exerciseId as argument
function* getAnswerStatsFromStoreOrInitialValue(user) {
  if (typeof user === "undefined") {
    return;
  }
  /*
  if (typeof user === "undefined") {
    throw new Error(
      "user object must be passed to getAnswerStatsFromFirestoreOrInitialValue."
    );
  }
  */
  let answerStats = INITIAL_ANSWER_STATS_STATE;

  if (user === null) {
    const answerStatsFromLocalStorage = getAnswerStatsFromLocalStorage();
    if (answerStatsFromLocalStorage !== null) {
      answerStats = Immutable.fromJS(answerStatsFromLocalStorage);
    }
  } else {
    try {
      const answerStatsDocumentSnapshot = yield call(getAnswerStatsSaga, user);
      if (answerStatsDocumentSnapshot.exists) {
        answerStats = Immutable.fromJS(answerStatsDocumentSnapshot.data());
      } else {
        const answerStatsFromLocalStorage = getAnswerStatsFromLocalStorage();
        if (answerStatsFromLocalStorage !== null) {
          answerStats = Immutable.fromJS(answerStatsFromLocalStorage);
        }
      }
    } catch (error) {
      console.log("getAnswerStatsFromFirestoreOrInitialValue error", error);
    }
  }

  // user === null or no value in Firestore
  // TODO put INITIAL_ANSWER_STATS_STATE into types as Immutable.Record
  try {
    yield put(answerStatsFetchedFromStore(answerStats));
  } catch (error) {
    console.error("Error in getAnswerStatsFromStoreOrInitialValue.", error);
  }
}

const answerStatsLocalStorageKey = (exerciseId) =>
  `musicdrill::answerStats/${exerciseId}`;

function getAnswerStatsFromLocalStorage(exerciseId = "notesOnFretboard") {
  const rawAnswerStats = localStorage.getItem(
    answerStatsLocalStorageKey(exerciseId)
  );
  if (rawAnswerStats === null) {
    return null;
  } else {
    return JSON.parse(rawAnswerStats);
  }
}

function* getSettingsFromStoreOrInitialValue(user) {
  if (typeof user === "undefined") {
    return;
  }
  /*
  if (typeof user === "undefined") {
    throw new Error(
      "user object must be passed to getSettingsFromStoreOrInitialValue."
    );
  }
  */
  let settings = INITIAL_SETTINGS_STATE;

  if (user === null) {
    const settingsFromLocalStorage = getSettingsFromLocalStorage();
    if (settingsFromLocalStorage !== null) {
      settings = Immutable.fromJS(settingsFromLocalStorage);
    }
  } else {
    try {
      const settingsDocumentSnapshot = yield call(getSettingsSaga, user);
      if (settingsDocumentSnapshot.exists) {
        settings = Immutable.fromJS(settingsDocumentSnapshot.data());
      } else {
        const settingsFromLocalStorage = getSettingsFromLocalStorage();
        if (settingsFromLocalStorage !== null) {
          settings = Immutable.fromJS(settingsFromLocalStorage);
        }
      }
    } catch (error) {
      console.log("getSettingsFromStoreOrInitialValue error", error);
    }
  }
  try {
    yield put(settingsFetchedFromStore(settings));
  } catch (error) {
    console.error("Error in getSettingsFromStoreOrInitialValue.", error);
  }
}

const settingsLocalStorageKey = (exerciseId) =>
  `musicdrill::settings/${exerciseId}`;

function getSettingsFromLocalStorage(exerciseId = "notesOnFretboard") {
  const rawSettings = localStorage.getItem(settingsLocalStorageKey(exerciseId));
  if (rawSettings === null) {
    return null;
  } else {
    return JSON.parse(rawSettings);
  }
}

function* checkAnswerSaga(question) {
  let answerIsCorrect;
  do {
    let answer;
    try {
      const submitAnswerAction = yield take(types.EXERCISE.ANSWER.SUBMIT);
      answer = submitAnswerAction.payload.answer;
    } catch (error) {
      console.error(
        "Error in checkAnswerSaga when getting submitted answer.",
        error
      );
    }
    answerIsCorrect = Questions.checkAnswer(question, answer);
    try {
      if (answerIsCorrect) {
        yield put(correctAnswer(answer));
        // Wait for new question so that we don't get any answers before question
        yield take(types.EXERCISE.QUESTION.SUCCESS);
      } else {
        yield put(wrongAnswer(answer));
      }
    } catch (error) {
      console.error("Error in checkAnswerSaga.", error);
    }
  } while (!answerIsCorrect);
}

function* answerTimeSaga() {
  let startTime;
  let endTime;
  try {
    startTime = yield call(Date.now);
    yield take(types.EXERCISE.ANSWER.CHECKED.CORRECT);
    endTime = yield call(Date.now);
  } catch (error) {
    console.error("Error in answerTimeSaga.", error);
  }
  const answerTime = endTime - startTime;
  return answerTime;
}

function* permanentlyStoreAnswerStats({ payload: { user } }) {
  // TODO get exerciseId as argument
  const exerciseId = "notesOnFretboard";
  try {
    while (true) {
      const {
        payload: { answerStats },
      } = yield take(types.EXERCISE.ANSWER_STATS.UPDATED_BY_CLIENT);
      if (user === null) {
        localStorage.setItem(
          answerStatsLocalStorageKey(exerciseId),
          JSON.stringify(answerStats.toJSON())
        );
      } else {
        yield setAnswerStatsSaga(answerStats, user);
      }
    }
  } finally {
    yield cancelled();
  }
}

function* permanentlyStoreSettings({ payload: { user } }) {
  // TODO get exerciseId as argument
  const exerciseId = "notesOnFretboard";
  try {
    while (true) {
      const {
        payload: { settings },
      } = yield take(types.EXERCISE.SETTINGS.UPDATED_BY_CLIENT);
      if (user === null) {
        localStorage.setItem(
          settingsLocalStorageKey(exerciseId),
          JSON.stringify(settings.toJSON())
        );
      } else {
        yield setSettingsSaga(settings, user);
      }
    }
  } finally {
    yield cancelled();
  }
}

function* keyboardAnswers() {
  const keyPressChannel = eventChannel((emitter) => {
    const keyboardEventListener = (keyboardEvent) => {
      const { key, altKey, metaKey, ctrlKey } = keyboardEvent;
      // Avoid catching key combinations like Ctrl+C
      if (!altKey && !metaKey && !ctrlKey) {
        if (["c", "d", "e", "f", "g", "a", "b"].includes(key)) {
          const upperCaseKey = key.toUpperCase();
          emitter(upperCaseKey);
        }
      }
    };
    document.addEventListener("keyup", keyboardEventListener);
    return () => {
      document.removeEventListener(keyboardEventListener);
    };
  });
  while (true) {
    try {
      const key = yield take(keyPressChannel);
      yield put(submitAnswer(key));
    } catch (error) {
      console.error("Error in keyboardAnswers saga.", error);
    }
  }
}

export default function* exerciseRootSaga() {
  try {
    yield all([
      fork(gameLoopSaga),
      takeLatest(loginTypes.SYNC_USER, permanentlyStoreAnswerStats),
      takeLatest(loginTypes.SYNC_USER, permanentlyStoreSettings),
      fork(startExerciseSaga),
      fork(syncQuestionsChannel),
      fork(syncAnswerStatsChannel),
      fork(syncSettingsChannel),
      fork(keyboardAnswers),
    ]);
  } catch (error) {
    // TODO trigger some global error?
    console.error("Error in exerciseRootSaga.", error);
  }
}

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { NavLink as RRNavLink, Route } from "react-router-dom";
import styled from "styled-components";

import { Col, Container, Row } from "../../util/styledBootstrapGridWrapper";
import Footer from "../Footer";
import LoginMenuContainer from "../LoginMenuContainer";
import Navigation from "../Navigation";
import Title from "../Title";
import { UnstyledLink } from "../Typography";

const PageTitleRow = styled(Row)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media screen and (max-width: 500px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;
PageTitleRow.displayName = "PageTitleRow";

const PageTitleCol = styled(Col)`
  /* Pushes next column to the rightworkaround until styled-bootstrap-grid
   * supports this */
  margin-right: auto;
`;
PageTitleCol.displayName = "PageTitleCol";

const HeaderContainer = styled.div`
  background-color: ${(props) => props.theme.headerBackgroundColor};
  color: ${(props) => props.theme.headerColor};
`;
HeaderContainer.displayName = "HeaderContainer";

const MainContainer = styled(Container)`
  overflow: hidden;
`;
MainContainer.displayName = "MainContainer";

const MainDiv = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
`;

const DefaultLayout = ({ component: Component, title, ...otherProps }) => {
  useEffect(() => {
    document.title =
      title === ""
        ? "MusicDrill | Fun & Easy Music Learning"
        : `${title} | MusicDrill`;
  }, [title]);

  return (
    <Route
      {...otherProps}
      // eslint-disable-next-line react/jsx-no-bind
      render={() => (
        <MainDiv>
          <HeaderContainer>
            <Container>
              <PageTitleRow>
                <PageTitleCol auto>
                  <UnstyledLink tag={RRNavLink} to="/">
                    <Title />
                  </UnstyledLink>
                </PageTitleCol>
                <Col auto>
                  <LoginMenuContainer />
                </Col>
              </PageTitleRow>
            </Container>
          </HeaderContainer>

          <Navigation />

          <MainContainer>
            <Component />
          </MainContainer>

          <Footer />
        </MainDiv>
      )}
    />
  );
};

DefaultLayout.propTypes = {
  component: PropTypes.elementType.isRequired,
  title: PropTypes.string,
};

DefaultLayout.defaultProps = {
  title: "",
};

export default DefaultLayout;

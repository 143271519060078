import { cancel, cancelled, fork, race, take } from "redux-saga/effects";

const RANDOM_VALUE =
  "To6L6DEbuUiVbSKVuddYv7r6wgheG7AnlwWhXogi9o1a5BVpEXbYSsSewZg1XHc6";

export function* combineLatest(channels, saga) {
  let sagaTask;
  let combinedChannelValues = channels.map(() => RANDOM_VALUE);

  try {
    // channelValue is usually an action but it doesn't necessarily have to be
    while (true) {
      const receivedChannelValues = yield race(channels.map(take));
      const index = receivedChannelValues.findIndex((value) => !!value);
      combinedChannelValues[index] = receivedChannelValues[index];

      if (combinedChannelValues.every((value) => value !== RANDOM_VALUE)) {
        if (sagaTask) {
          yield cancel(sagaTask);
        }
        sagaTask = yield fork(saga, combinedChannelValues);
      }
    }
  } finally {
    if (yield cancelled()) {
      if (sagaTask) {
        yield cancel(sagaTask);
      }
      combinedChannelValues = channels.map(() => RANDOM_VALUE);
    }
  }
}

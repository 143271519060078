import React, { useState, useCallback } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";

import urls from "../urls";
import { Container } from "../util/styledBootstrapGridWrapper";

const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggle = useCallback(() => setIsNavOpen(!isNavOpen), [isNavOpen]);

  return (
    <Navbar color="light" light expand="md">
      <Container>
        <Collapse isOpen={isNavOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink tag={RRNavLink} exact to={urls.chordProgressionDrill}>
                {"Chord Progression"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to={urls.contextual}>
                {"Contextual"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to={urls.chordConstruction}>
                {"Chord Construction"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to={urls.intervalTraining}>
                {"Interval Training"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to={urls.fretboardTraining}>
                {"Fretboard Notes"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RRNavLink} to={urls.fretboardInterval}>
                {"Fretboard Intervals"}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        {/* eslint-disable-next-line react/forbid-component-props */}
        <NavbarToggler className={"float-right"} onClick={toggle} />
      </Container>
    </Navbar>
  );
};

export default Navigation;

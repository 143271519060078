import { connect } from "react-redux";

export default (
  mapStateToProps = null,
  mapDispatchToProps = {},
  mergeProps = null,
  options = {}
) => {
  options.pure = typeof options.pure === "undefined" ? false : options.pure;
  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    options
  );
};

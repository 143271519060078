import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./sagas/reducers/reducers";
import rootSaga from "./sagas/sagas";

const sagaMiddleware = createSagaMiddleware();
const middleWare = [sagaMiddleware];

const enhancers = [applyMiddleware(...middleWare)];

// See https://github.com/zalmoxisus/redux-devtools-extension#usage
if (process.env.NODE_ENV === "development") {
  const devToolsEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();
  if (devToolsEnhancer) {
    enhancers.push(devToolsEnhancer);
  }
}

const store = createStore(rootReducer, compose(...enhancers));

sagaMiddleware.run(rootSaga);

export default store;

import React from "react";
import styled from "styled-components";

const TitleWrapper = styled.span`
  color: ${(props) => props.theme.headerColor};
  font-family: ${(props) => props.theme.titleFont};
  font-size: 3rem;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
  }
`;
TitleWrapper.displayName = "TitleWrapper";

const TitleFirst = styled.span`
  font-weight: 300;
`;
TitleFirst.displayName = "TitleFirst";

const TitleSecond = styled.span`
  font-weight: 500;
  &:after {
    content: "beta";
    position: relative;
    padding: 1px 3px;
    background-color: ${(props) => props.theme.betaBadgeBackgroundColor};
    color: ${(props) => props.theme.betaBadgeColor};
    top: 12px;
    left: 0;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    font-family: sans-serif;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: bold;
    vertical-align: top;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.7);

    @media screen and (max-width: 500px) {
      top: 6px;
      font-size: 10px;
      line-height: 10px;
    }
  }
`;
TitleSecond.displayName = "TitleSecond";

const Title = () => (
  <TitleWrapper>
    <TitleFirst>{"Music"}</TitleFirst>
    <TitleSecond>{"Drill"}</TitleSecond>
  </TitleWrapper>
);
Title.displayName = "Title";

export default Title;

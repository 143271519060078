function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

/**
 * pareto(x) = 0.8^(log0.2(x)); log0.2 = 0.2-based logarithm
 * pareto(0.2) = 0.8
 * pareto(0.04) = 0.64
 * pareto(1) = 1
 */
/*
function pareto(x) {
  // TODO should this just return  ?
  if (x <= 0) {
    console.warn('Value for pareto() must be positive');
    return Number.MIN_VALUE;
  } else {
    return Math.pow(0.8, Math.log(x) / Math.log(0.2));
  }
}

function sigmoid(x, x0=0, k=1) {
  return 1 / (1 + Math.exp(-k*(x-x0)));
}
*/
/**
 * Median of an already sorted list
 * @param list
 * @returns {*}
 */
/*
function median(list) {
  if(list.length === 0) {
    return Math.NaN;
  } else if (list.length === 1) {
    return list[0];
  } else if(list.length % 2 === 0) {
    // average of the two middle values
    return (list[list.length / 2 - 1] + list[list.length / 2]) / 2;
  } else {
    return list[(list.length - 1) / 2]
  }
}


function average(list) {
  if (list.size === 0) {
    return Math.NaN;
  }
  const sum = list.reduce((acc, value) => acc + value, 0);
  return sum / list.size;
}

function standardDeviation(list) {
  if (list.size === 0) {
    return NaN;
  }
  const averageValue = average(list);
  const sumOfSquares = list
    .map((i) => Math.pow((i - averageValue), 2))
    .reduce((acc, value) => acc + value, 0);
  return Math.sqrt(sumOfSquares / list.size);
}
*/

export {
  //average,
  clamp,
  //median,
  //pareto,
  //sigmoid,
  //standardDeviation,
};

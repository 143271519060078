import { all, fork } from "redux-saga/effects";

import exerciseRootSaga from "./exerciseSagas";
import firestoreRootSaga from "./firestoreSagas";
import loginRootSaga from "./loginSagas";

export default function* rootSaga() {
  try {
    yield all([
      fork(exerciseRootSaga),
      fork(firestoreRootSaga),
      fork(loginRootSaga),
    ]);
  } catch (error) {
    console.error("rootSaga failed", error);
  }
}

import { List, Map } from "immutable";
import { clamp } from "../util/Math";

// Update answer stats on correct answer
// TODO should update also on wrong answers?
const updateAnswerStats = (
  currentQuestion,
  answer,
  answerTime,
  answerStats
) => {
  // TODO ignore first answer
  const currentQuestionId = currentQuestion.get("id");
  let answerIndex = answerStats
    .get("list", List())
    .findIndex(a => a.get("id") === currentQuestionId);
  // TODO use withMutations
  let updatedAnswers = answerStats;
  if (answerIndex === -1) {
    const initialAnswer = Map({ id: currentQuestionId });
    updatedAnswers = answerStats.update("list", List(), list =>
      list.push(initialAnswer)
    );
    answerIndex = updatedAnswers.get("list").size - 1;
  }
  // TODO clamp to max answer time or something
  const clampedAnswerTime = clamp(Math.round(answerTime), 0, 60000);

  updatedAnswers = updatedAnswers.update("count", 0, count => count + 1);

  // TODO use .withMutations() to optimize the updates
  return updatedAnswers
    .updateIn(["list", answerIndex, "lastAsked"], 0, () =>
      updatedAnswers.get("count")
    )
    .updateIn(["list", answerIndex, "latestAnswerTimes"], List(), oldTimes =>
      oldTimes.push(clampedAnswerTime).takeLast(10)
    )
    .updateIn(["list", answerIndex, "count"], 0, oldCount => oldCount + 1);
};

const heuristicAlgorithm = ({ questionsWithAnswerStats, settings }) => {
  const DONT_ASK_RECENTLY_ASKED_AGAIN_COUNT = 2; // don"t ask again the last N questions
  const LAST_ANSWER_COUNT = 5; // how many times in a row user needs to answer under the threshold time to be considered knowing the answer
  const CORRECT_ANSWER_THRESHOLD_TIME_MS = 2000; // if answering takes more, user doesn"t know
  const MAX_NUMBER_OF_UNKNOWN_QUESTIONS_AT_A_TIME = 4; // working memory size = 7 +/- 2

  // TODO: verify that questionsWithAnswerStats and settings have same exerciseId

  const filterByStringsAndFrets = question => {
    const coveredFrets = settings.get("frets");
    const coveredStrings = settings.get("strings");
    return (
      coveredFrets.includes(question.get("fret")) &&
      coveredStrings.includes(question.get("string"))
    );
  };

  const list = questionsWithAnswerStats
    .get("list")
    .filter(filterByStringsAndFrets);

  const previousQuestionNumber = questionsWithAnswerStats.get("count");
  const recentlyAskedQuestions = list.filter(
    q =>
      q.get("lastAsked") >
      previousQuestionNumber - DONT_ASK_RECENTLY_ASKED_AGAIN_COUNT
  );

  const userDoesntKnowYet = question => {
    const latestAnswerTimes = question.get("latestAnswerTimes") || List();
    return (
      latestAnswerTimes.size === 0 ||
      latestAnswerTimes
        .takeLast(LAST_ANSWER_COUNT)
        .some(answerTime => answerTime > CORRECT_ANSWER_THRESHOLD_TIME_MS)
    );
  };
  const hasNotBeenJustRecentlyAsked = (recentlyAskedQuestions, question) => {
    return recentlyAskedQuestions.every(
      recentQuestion => recentQuestion.get("id") !== question.get("id")
    );
  };

  // TODO use withMutations
  const preferableQuestionsToAsk = list
    .filter(userDoesntKnowYet)
    .take(MAX_NUMBER_OF_UNKNOWN_QUESTIONS_AT_A_TIME)
    .filter(question =>
      hasNotBeenJustRecentlyAsked(recentlyAskedQuestions, question)
    );

  const questionsUserAlreadyKnowsAndNotRecentlyAsked = list
    .filter(question =>
      hasNotBeenJustRecentlyAsked(recentlyAskedQuestions, question)
    )
    .filter(question => !userDoesntKnowYet(question));

  if (
    preferableQuestionsToAsk.size > 0 &&
    (preferableQuestionsToAsk.size >=
      MAX_NUMBER_OF_UNKNOWN_QUESTIONS_AT_A_TIME ||
      Math.random() < 0.8 ||
      questionsUserAlreadyKnowsAndNotRecentlyAsked.size === 0)
  ) {
    const randomIndex = clamp(
      Math.floor(Math.random() * preferableQuestionsToAsk.size),
      0,
      preferableQuestionsToAsk.size - 1
    );
    return preferableQuestionsToAsk.get(randomIndex);
  } else {
    // TODO ask questions not asked in a long time
    const randomIndex = clamp(
      Math.floor(
        Math.random() * questionsUserAlreadyKnowsAndNotRecentlyAsked.size
      ),
      0,
      questionsUserAlreadyKnowsAndNotRecentlyAsked.size - 1
    );
    return questionsUserAlreadyKnowsAndNotRecentlyAsked.get(randomIndex);
  }
};

const Memo = {
  updateAnswerStats,
  heuristicAlgorithm,
};

export default Memo;

import styled from "styled-components";

import { Col, Row } from "../util/styledBootstrapGridWrapper";

export const MainCol = styled(Col)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;
MainCol.displayName = "MainCol";

export const TopRow = styled(Row)`
  padding-top: 2rem;
`;
TopRow.displayName = "TopRow";

export const BottomRow = styled(Row)`
  padding-bottom: 2rem;
`;
BottomRow.displayName = "BottomRow";

export const SingleRow = styled(Row)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100%;
`;
SingleRow.displayName = "SingleRow";

export const UnpaddedSingleRow = styled(Row)`
  min-height: 100%;
`;
UnpaddedSingleRow.displayName = "UnpaddedSingleRow";

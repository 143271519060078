import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { v4 as uuid } from "uuid";

import { firebaseAuth, firestore } from "../util/firebase";
import { H3 } from "../ui/Typography";

interface CloudflareData {
  ip: string;
  colo: string;
  loc: string;
}

interface FeedbackData extends CloudflareData {
  text: string;
  uid: string | null;
  path: string;
}

export function FeedbackForm() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <Button id="feedback-toggle-button" color="link" onClick={toggle}>
        {"\u2665 Give Feedback"}
      </Button>
      <br />
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <H3>{"Feedback"}</H3>
            <PlainFeedbackForm />
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
}

export function PlainFeedbackForm() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cloudflareData, setCloudflareData] = useState<CloudflareData>({
    ip: "",
    colo: "",
    loc: "",
  });
  const [text, setText] = useState("");

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setIsSubmitted(true);
      const now = new Date();
      const dateString = `${now.getUTCFullYear()}-${
        now.getUTCMonth() + 1
      }-${now.getUTCDate()}-${now.getUTCHours()}-${now.getUTCMinutes()}`;
      const uniqueId = uuid();
      const feedbackId = `${dateString}-${uniqueId}`;
      const docRef = firestore.doc(`feedback/${feedbackId}`);
      const feedbackData: FeedbackData = {
        text: text.substr(0, 50000),
        uid: firebaseAuth.currentUser ? firebaseAuth.currentUser.uid : null,
        path: window.location.pathname,
        ...cloudflareData,
      };
      // This is async but let's not await or handle errors here; best-effort.
      docRef.set(feedbackData);
    },
    [cloudflareData, text]
  );

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;
    setText(text);
  }

  useEffect(() => {
    fetch("https://www.cloudflare.com/cdn-cgi/trace").then(async (response) => {
      try {
        const body = await response.text();
        const ipMatch = body.match(/ip=(.+)\n/);
        const ip = ipMatch === null ? "" : ipMatch[1];
        const coloMatch = body.match(/colo=(.+)\n/);
        const colo = coloMatch === null ? "" : coloMatch[1];
        const locMatch = body.match(/loc=(.+)\n/);
        const loc = locMatch === null ? "" : locMatch[1];
        setCloudflareData({ ip, colo, loc });
      } catch (e) {}
    });
  }, []);

  return (
    <>
      {isSubmitted && (
        <p>{"Thank you for your feedback! We'll get right on it."}</p>
      )}
      {!isSubmitted && (
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Input type="textarea" value={text} onChange={onChange} />
          </FormGroup>
          <Button color="primary">{"Submit"}</Button>
        </Form>
      )}
    </>
  );
}

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Button from "reactstrap/lib/Button";

import urls from "../../urls";
import { BottomRow, SingleRow, TopRow } from "../Layout";
import { H2, P } from "../Typography";
import { logout } from "../../sagas/actions/loginActions";
import { Col, Row } from "../../util/styledBootstrapGridWrapper";
import connectImmutablePureComponent from "../../util/connectImmutablePureComponent";

const SignInLink = ({ ...props }) => <Link to={urls.signIn} {...props} />;

// Inspired by https://github.com/jacwright/date.format/blob/master/date.format.js
const dateFromMetadataTimestamp = (timestamp) => {
  const longMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daySuffix = (day) =>
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${longMonths[month]} ${day}${daySuffix(day)}, ${year}`;
};

export const Profile = ({ loggedIn, user, logout }) =>
  loggedIn ? (
    <React.Fragment>
      <TopRow>
        <Col>
          <H2>{user.displayName}</H2>
        </Col>
      </TopRow>
      <Row>
        <Col>
          <P>
            {"Joined "}
            {dateFromMetadataTimestamp(user.metadata.creationTime)}
            {"."}
          </P>
        </Col>
      </Row>
      <BottomRow>
        <Col>
          <Button outline color="danger" size="sm" onClick={logout}>
            {"Sign out"}
          </Button>
        </Col>
      </BottomRow>
    </React.Fragment>
  ) : (
    <SingleRow>
      <Col>
        <P>
          {"You are not currently signed in. Please, "}
          <SignInLink>{"sign in here."}</SignInLink>
        </P>
      </Col>
    </SingleRow>
  );
Profile.displayName = "Profile";
Profile.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
};
Profile.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  loggedIn: state.getIn(["login", "loggedIn"]),
  user: state.getIn(["login", "user"]),
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connectImmutablePureComponent(
  mapStateToProps,
  mapDispatchToProps
)(Profile);

//import Immutable from 'immutable';
import { combineReducers } from "redux-immutable";

import {
  questionReducer,
  answerReducer,
  answerStatsReducer,
  settingsReducer,
} from "./exerciseReducers";
import loginReducer from "./loginReducer";

const rootReducer = combineReducers({
  question: questionReducer,
  answer: answerReducer,
  answerStats: answerStatsReducer,
  login: loginReducer,
  settings: settingsReducer,
});

export default rootReducer;

import ReduxSagaFirebase from "redux-saga-firebase";

import { firebaseApp } from "../util/firebase";

let reduxSagaFirebaseInstance = new ReduxSagaFirebase(firebaseApp);

// Reset function for tests
export const __reset = () => {
  reduxSagaFirebaseInstance = new ReduxSagaFirebase(firebaseApp);
};

const reduxSagaFirebase = () => reduxSagaFirebaseInstance;

export default reduxSagaFirebase;

const theme = {
  textColor: "#292b2c",
  lightTextColor: "#787e81",
  headerBackgroundColor: "#224870",
  headerColor: "#ffffff",
  sidebarBackgroundColor: "#f2faff",
  titleFont: `"Exo 2", sans-serif`,
  betaBadgeBackgroundColor: "#ee8e4a",
  betaBadgeColor: "#ffffff",
  linkColor: "#0275d8",
  linkColorOnDarkBackground: "#bac5ff",
};

export default theme;

export const types = {
  FIRESTORE: {
    SYNC: {
      SUCCESS: "FIRESTORE.SYNC.SUCCESS",
      FAILURE: "FIRESTORE.SYNC.FAILURE",
    },
  },
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
};

export const syncStorage = memo => ({
  type: types.STORAGE.SYNC,
  payload: {
    memo,
  },
});

export const syncSuccess = documentSnapshot => ({
  type: types.FIRESTORE.SYNC.SUCCESS,
  payload: {
    documentSnapshot,
  },
});

export const syncFailure = error => ({
  type: types.FIRESTORE.SYNC.FAILURE,
  payload: {
    error,
  },
});

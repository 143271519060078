import PropTypes from "prop-types";
import React from "react";

import ExternalLinkIcon from "./ExternalLinkIcon";

// We need to pass ...props, otherwise styled().withComponent(ExternalLink)
// won't work.
/* eslint-disable react/jsx-no-target-blank */
const ExternalLink = ({ url, text, ...props }) => (
  <a href={url} target="_blank" rel="noopener" {...props}>
    {text} <ExternalLinkIcon />
  </a>
);
/* eslint-enable react/jsx-no-target-blank */

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ExternalLink;

import React from "react";

import { Row, Col } from "../util/styledBootstrapGridWrapper";
import ExternalLink from "./ExternalLink";
import { PlainFeedbackForm } from "./FeedbackForm";
import { MainCol, TopRow } from "./Layout";
import { H2, H3 } from "./Typography";

export default function ContactPage() {
  return (
    <>
      <TopRow>
        <Col lg={8}>
          <H2>{"Contact"}</H2>
        </Col>
      </TopRow>
      <Row>
        <MainCol lg={8}>
          <H3>{"Contact form"}</H3>
          <PlainFeedbackForm />
        </MainCol>
      </Row>
      <Row>
        <MainCol auto>
          <H3>{"Other channels"}</H3>
          <address>
            {"Email: "}
            <a href="mailto:contact@musicdrill.com">
              {"contact@musicdrill.com"}
            </a>
          </address>
          <address>
            {"Reddit: "}
            <ExternalLink
              url="https://www.reddit.com/user/MusicDrill_com"
              text="MusicDrill_com"
            />
          </address>
        </MainCol>
      </Row>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Col, Container, Row } from "../util/styledBootstrapGridWrapper";
import urls from "../urls";
import ExternalLink from "./ExternalLink";
import { P } from "./Typography";

// const firstColStyle = {size: 2, offset: 6};

const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.headerBackgroundColor};
  color: ${(props) => props.theme.headerColor};
  padding-top: 1rem;
  padding-bottom: 1rem;
`;
StyledFooter.displayName = "StyledFooter";

const PushRightCol = styled(Col)`
  /* Pushes next column to the rightworkaround until styled-bootstrap-grid
   * supports this */
  margin-right: auto;
`;
PushRightCol.displayName = "PushRightCol";

const FooterAnchorLink = styled.a`
  &,
  &:active,
  &:focus,
  &:hover {
    color: ${(props) => props.theme.linkColorOnDarkBackground};
  }
  /* Fill svg icons */
  path {
    fill: ${(props) => props.theme.linkColorOnDarkBackground};
  }
  text-decoration: none;
  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;
FooterAnchorLink.displayName = "FooterAnchorLink";

const FooterExternalLink = (props) => (
  <FooterAnchorLink as={ExternalLink} {...props} />
);
FooterExternalLink.displayName = "FooterExternalLink";

const FooterRouterLink = (props) => <FooterAnchorLink as={Link} {...props} />;
FooterRouterLink.displayName = "FooterRouterLink";

const Footer = () => (
  <StyledFooter>
    <Container>
      <Row>
        <PushRightCol auto>
          <P>
            <i>{"“Probably the most efficient way to learn music.”"}</i>
          </P>
        </PushRightCol>
      </Row>
      <Row>
        <PushRightCol auto>
          <small>
            <FooterAnchorLink href="/terms-of-service">
              {"Terms of Service"}
            </FooterAnchorLink>
            {" | "}
            <FooterAnchorLink href="/privacy-policy">
              {"Privacy Policy"}
            </FooterAnchorLink>
            {" | "}
            <FooterRouterLink to={urls.contact}>{"Contact"}</FooterRouterLink>
          </small>
        </PushRightCol>
        <Col auto>
          <small>{"Copyright © 2017-2020 Siniteq Solutions Ltd"}</small>
        </Col>
      </Row>
    </Container>
  </StyledFooter>
);

export default Footer;

import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import { SingleRow } from "../Layout";
import Config from "../../Config";
import { firebaseAuth } from "../../util/firebase";
import { Col } from "../../util/styledBootstrapGridWrapper";

// TODO Use Context API to inject the firebaseconfig and auth
const SignIn = () => (
  <SingleRow>
    <Col>
      <StyledFirebaseAuth
        uiConfig={Config.firebaseUi}
        firebaseAuth={firebaseAuth}
      />
    </Col>
  </SingleRow>
);
SignIn.displayName = "SignIn";

export default SignIn;

import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const commonHeaderStyle = css`
  font-family: "Exo 2", sans-serif !important;
  text-transform: uppercase !important;
`;

export const H1 = styled.h1`
  ${commonHeaderStyle}
  font-size: 2.5rem;
`;
H1.displayName = "H1";

export const H2 = styled.h2`
  ${commonHeaderStyle}
  font-size: 2rem;
`;
H2.displayName = "H2";

export const H3 = styled.h3`
  ${commonHeaderStyle}
  font-size: 1.75rem;
`;
H3.displayName = "H3";

export const H4 = styled.h4`
  ${commonHeaderStyle}
  font-size: 1.3rem;
`;
H4.displayName = "H4";

export const H5 = styled.h5`
  ${commonHeaderStyle}
  font-size: 1.15rem;
`;
H5.displayName = "H5";

export const H6 = styled.h6`
  ${commonHeaderStyle}
  font-size: 1rem;
`;
H6.displayName = "H6";

// Used in *Drill components to add some extra margin to subheaders
export const QuestionH3 = styled(H3)`
  position: relative;
  margin-top: 1rem;
  & > svg {
    display: inline-block;
    position: absolute;
    right: 0px;
    width: 2rem;
    height: 2rem;
  }
`;
QuestionH3.displayName = "QuestionH3";

export const P = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  max-width: 40em;
`;
P.displayName = "P";

export const UnstyledLink = styled(Link)`
  &,
  &:hover,
  &:active {
    text-decoration: none !important;
  }
`;
UnstyledLink.displayName = "UnstyledLink";

export const Center = styled.div`
  text-align: center;
`;
Center.displayName = "Center";

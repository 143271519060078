// NOTE: When you add anything here, make sure to add any relevant corresponding
// code into src/util/__mocks__/Config.ts also.

const baseConfig: {
  exercise: Object;
  firebase: Object;
  firebaseUi: firebaseui.auth.Config;
} = {
  exercise: {
    nextQuestionDelayInMs: 2000,
  },
  firebase: {},
  firebaseUi: {
    signInFlow: "popup",
    signInOptions: [
      "google.com", // firebase.auth.GoogleAuthProvider.PROVIDER_ID
      "facebook.com", // firebase.auth.FacebookAuthProvider.PROVIDER_ID
      "password", // firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    signInSuccessUrl: "/",
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        if (
          authResult.additionalUserInfo.isNewUser &&
          authResult.additionalUserInfo.providerId === "password" &&
          !authResult.user.emailVerified
        ) {
          authResult.user.sendEmailVerification();
        }
        return true; // true = page is redirected to signInSuccessUrl
      },
      signInFailure: (error) => {
        // Some unrecoverable error occurred during sign-in.
        console.log("sign in failed", error);
        // FIXME This is only to satisfy the type checker.
        // See https://github.com/firebase/firebaseui-web/issues/770
        return Promise.resolve();
      },
    },
  },
};

const productionConfig = {
  ...baseConfig,
  firebase: {
    ...baseConfig.firebase,
    apiKey: "AIzaSyAlxzDL9xrrSoYPyGwZHT9wjdKqUEMEFBw",
    databaseURL: "https://music-drill.firebaseio.com",
    storageBucket: "music-drill.appspot.com",
    authDomain: "music-drill.firebaseapp.com",
    messagingSenderId: "109390489596",
    projectId: "music-drill",
    appId: "1:109390489596:web:7fce594296be40e4edb479",
    measurementId: "G-MFCF7Y3946", // Google Analytics
  },
  firebaseUi: {
    ...baseConfig.firebaseUi,
    tosUrl: "https://musicdrill.com/terms-of-service",
    privacyPolicyUrl: "https://musicdrill.com/privacy-policy",
  },
};

const developmentConfig = {
  ...baseConfig,
  firebase: {
    ...baseConfig.firebase,
    apiKey: "AIzaSyCEKR7JV2xUEIB8xa4fpnCAWnn7OAbnkxY",
    databaseURL: "https://music-drill-dev.firebaseio.com",
    storageBucket: "music-drill-dev.appspot.com",
    authDomain: "music-drill-dev.firebaseapp.com",
    messagingSenderId: "1039590766151",
    projectId: "music-drill-dev",
    appId: "1:1039590766151:web:f794952e8dcb184f656362",
  },
  firebaseUi: {
    ...baseConfig.firebaseUi,
    tosUrl: "https://music-drill-dev.firebaseapp.com/terms-of-service",
    privacyPolicyUrl: "https://music-drill-dev.firebaseapp.com/privacy-policy",
  },
};

const config =
  process.env.REACT_APP_ENV === "production"
    ? productionConfig
    : developmentConfig;

export default config;

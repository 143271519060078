import React, { ReactNode } from "react";
import { Link as RRLink } from "react-router-dom";
import {
  Card as RSCard,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle as RSCardSubtitle,
  Button,
} from "reactstrap";
import styled from "styled-components";
import urls from "../urls";

import { Col } from "../util/styledBootstrapGridWrapper";
import { FeedbackForm } from "./FeedbackForm";
import Fretboard from "./Fretboard";
import { SingleRow } from "./Layout";

const CardSubtitle = styled(RSCardSubtitle)`
  color: #6c757d;
`;

const Link = styled(RRLink)`
  &,
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
`;

const Card = styled(RSCard)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #fcfcfc;
  & svg {
    max-height: 100px; // limit the size of the fretboard on mobile
  }
`;

const CardHeader = styled.div`
  font-family: "Exo 2", sans-serif !important;
  font-size: 2.5rem;
  line-height: 77px; // match the fretboard height
  text-align: center;
`;

const FloatRightButton = styled(Button)`
  float: right;
`;

interface DrillCardProps {
  header: ReactNode;
  title: string;
  url: string;
  category: "Ear Training" | "Guitar Fretboard" | "Memorization";
  description: string;
}

function DrillCard(props: DrillCardProps) {
  const Header =
    typeof props.header === "string" ? (
      <CardHeader>{props.header}</CardHeader>
    ) : (
      props.header
    );

  return (
    <Col lg={4}>
      <Link to={props.url}>
        <Card>
          <CardBody>{Header}</CardBody>
          <CardBody>
            <CardTitle tag="h5">{props.title}</CardTitle>
            <CardSubtitle tag="h6">{props.category}</CardSubtitle>
            <CardText>{props.description}</CardText>
            <FloatRightButton color="primary">
              {"Go to this drill!"}
            </FloatRightButton>
          </CardBody>
        </Card>
      </Link>
    </Col>
  );
}

export default function Frontpage() {
  return (
    <>
      <SingleRow>
        <DrillCard
          header="I IV vi V"
          title="Chord Progression"
          url={urls.chordProgressionDrill}
          category="Ear Training"
          description="Learn to recognize different chord progressions by ear."
        />
        <DrillCard
          header="DO RE MI FA"
          title="Contextual Scale Degrees"
          url={urls.contextual}
          category="Ear Training"
          description="Learn to recognize scale degrees in musical context."
        />
        <DrillCard
          header="C - E - G"
          title="Chord Construction"
          url={urls.chordConstruction}
          category="Memorization"
          description="You will learn how chords are constructed."
        />
        <DrillCard
          header="G + major 3rd"
          title="Interval Training"
          url={urls.intervalTraining}
          category="Memorization"
          description="Memorize intervals between notes so that you know them instantly without thinking."
        />
        <DrillCard
          header={<Fretboard notes={[{ string: 5, fret: 3, label: "C" }]} />}
          title="Fretboard Notes"
          url={urls.fretboardTraining}
          category="Guitar Fretboard"
          description="Master the fretboard by memorizing all the note names."
        />
        <DrillCard
          header={
            <Fretboard
              notes={[
                { string: 6, fret: 5, label: "A" },
                { string: 5, fret: 7, label: "E" },
              ]}
            />
          }
          title="Fretboard Intervals"
          url={urls.fretboardInterval}
          category="Guitar Fretboard"
          description="Study the interval patterns on a fretboard until they become a second nature to you."
        />
      </SingleRow>
      <FeedbackForm />
    </>
  );
}

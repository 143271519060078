import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import styled from "styled-components";

import urls from "../urls";
import { UnstyledLink } from "../ui/Typography";

const InitialsCircle = styled.div`
  border-radius: 50%;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.3);
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
`;
InitialsCircle.displayName = "InitialsCircle";

const SignInLink = (props) => <Link to={urls.signIn} {...props} />;

// TODO refactor into a stateless component
/* eslint-disable react/prefer-stateless-function */
export class LoginMenu extends React.Component {
  static propTypes = {
    signInStatus: PropTypes.oneOf(["loggedIn", "loggedOut", "unknown"])
      .isRequired,
    displayName: PropTypes.string,
    initials: PropTypes.string,
  };

  static defaultProps = {
    displayName: "",
    initials: "",
  };

  render() {
    const { signInStatus, initials, displayName } = this.props;

    return signInStatus === "loggedOut" ? (
      <Button tag={SignInLink} color="success">
        {"Sign in"}
      </Button>
    ) : signInStatus === "loggedIn" ? (
      <React.Fragment>
        <UnstyledLink to={urls.profile}>
          <InitialsCircle title={`You are signed in as ${displayName}`}>
            {initials}
          </InitialsCircle>
        </UnstyledLink>
      </React.Fragment>
    ) : (
      <div />
    );
  }
}

export default LoginMenu;

export const types = {
  EXERCISE: {
    START: {
      REQUEST: "EXERCISE.START.REQUEST",
      SUCCESS: "EXERCISE.START.SUCCESS",
      FAILURE: "EXERCISE.START.FAILURE",
    },
    QUESTIONS: {
      REQUEST: "EXERCISE.QUESTIONS.REQUEST",
      SUCCESS: "EXERCISE.QUESTIONS.SUCCESS",
      FAILURE: "EXERCISE.QUESTIONS.FAILURE",
    },
    QUESTION: {
      REQUEST: "EXERCISE.QUESTION.REQUEST",
      SUCCESS: "EXERCISE.QUESTION.SUCCESS",
      FAILURE: "EXERCISE.QUESTION.FAILURE",
    },
    ANSWER: {
      SUBMIT: "EXERCISE.ANSWER.SUBMIT",
      CHECKED: {
        CORRECT: "EXERCISE.ANSWER.CHECKED.CORRECT",
        WRONG: "EXERCISE.ANSWER.CHECKED.WRONG",
      },
      ERROR: "EXERCISE.ANSWER.ERROR", // e.g. answer data in wrong format
    },
    ANSWER_STATS: {
      UPDATED_BY_CLIENT: "EXERCISE.ANSWER_STATS.UPDATED_BY_CLIENT",
      REQUEST: "EXERCISE.ANSWER_STATS.REQUEST",
      SUCCESS: "EXERCISE.ANSWER_STATS.SUCCESS",
      FAILURE: "EXERCISE.ANSWER_STATS.FAILURE",
    },
    SETTINGS: {
      UPDATED_BY_CLIENT: "EXERCISE.SETTINGS.UPDATED_BY_CLIENT",
      REQUEST: "EXERCISE.SETTINGS.REQUEST",
      SUCCESS: "EXERCISE.SETTINGS.SUCCESS",
      FAILURE: "EXERCISE.SETTINGS.FAILURE",
    },
  },
};

export const startExercise = () => {
  return {
    type: types.EXERCISE.START.REQUEST,
  };
};

export const startExerciseSuccess = () => ({
  type: types.EXERCISE.START.SUCCESS,
});

export const loadQuestions = (questions) => ({
  type: types.EXERCISE.QUESTIONS.SUCCESS,
  payload: {
    questions,
  },
});

export const requestNewQuestion = () => ({
  type: types.EXERCISE.QUESTION.REQUEST,
});

export const askNewQuestion = (question) => ({
  type: types.EXERCISE.QUESTION.SUCCESS,
  payload: {
    question,
  },
});

export const submitAnswer = (answer) => ({
  type: types.EXERCISE.ANSWER.SUBMIT,
  payload: {
    answer,
  },
});

export const correctAnswer = (answer) => ({
  type: types.EXERCISE.ANSWER.CHECKED.CORRECT,
  payload: {
    answer,
  },
});

export const wrongAnswer = (answer) => ({
  type: types.EXERCISE.ANSWER.CHECKED.WRONG,
  payload: {
    answer,
  },
});

export const exerciseMemo = (exercise) => ({
  type: types.EXERCISE.UPDATE.REQUEST,
  payload: {
    exercise,
  },
});

export const answerStatsUpdatedByClient = (answerStats) => ({
  type: types.EXERCISE.ANSWER_STATS.UPDATED_BY_CLIENT,
  payload: {
    answerStats,
  },
});

export const answerStatsFetchedFromStore = (answerStats) => ({
  type: types.EXERCISE.ANSWER_STATS.SUCCESS,
  payload: {
    answerStats,
  },
});

export const settingsUpdatedByClient = (settings) => ({
  type: types.EXERCISE.SETTINGS.UPDATED_BY_CLIENT,
  payload: {
    settings,
  },
});

export const settingsFetchedFromStore = (settings) => ({
  type: types.EXERCISE.SETTINGS.SUCCESS,
  payload: {
    settings,
  },
});

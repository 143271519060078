import { call, fork, race, take } from "redux-saga/effects";

import {
  types as firestoreTypes,
  //syncSuccess,
  //syncFailure,
} from "./actions/firestoreActions";

import reduxSagaFirebase from "./reduxSagaFirebase";

export function* getAnswerStatsSaga(user, exerciseId = "notesOnFretboard") {
  if (user === null) {
    throw new Error(
      "user can't be null when fetching answerStats from Firestore."
    );
  }
  try {
    return yield call(
      reduxSagaFirebase().firestore.getDocument,
      `users/${user.uid}/answerStats/${exerciseId}`
    );
  } catch (error) {
    console.error("Error in getAnswerStatsSaga.", error);
    return null;
  }
}

/**
 * Creates new or overwrites existing one.
 *
 * @param {Object} answerStats
 * @param {Object} user
 * @param {String} exerciseId
 */
export function* setAnswerStatsSaga(
  answerStats,
  user,
  exerciseId = "notesOnFretboard"
) {
  if (user === null) {
    throw new Error(
      "user can't be null when storing answerStats to Firestore."
    );
  }
  try {
    yield call(
      reduxSagaFirebase().firestore.setDocument,
      `users/${user.uid}/answerStats/${exerciseId}`,
      answerStats.toJS()
    );
  } catch (error) {
    console.log("set failed", error);
  }
}

export function* getSettingsSaga(user, exerciseId = "notesOnFretboard") {
  if (user === null) {
    throw new Error(
      "user can't be null when fetching settings from Firestore."
    );
  }
  try {
    return yield call(
      reduxSagaFirebase().firestore.getDocument,
      `users/${user.uid}/exerciseSettings/${exerciseId}`
    );
  } catch (error) {
    console.error("Error in getSettingsSaga.", error);
    return null;
  }
}

export function* setSettingsSaga(
  settings,
  user,
  exerciseId = "notesOnFretboard"
) {
  if (user === null) {
    throw new Error("user can't be null when storing settings to Firestore.");
  }
  try {
    yield call(
      reduxSagaFirebase().firestore.setDocument,
      `users/${user.uid}/exerciseSettings/${exerciseId}`,
      settings.toJS()
    );
  } catch (error) {
    console.log("Error in setSettingsSaga.", error);
  }
}

function* debugSaga() {
  while (true) {
    try {
      const result = yield race({
        documentSnapshot: take(firestoreTypes.FIRESTORE.SYNC.SUCCESS),
        error: take(firestoreTypes.FIRESTORE.SYNC.FAILURE),
      });
      console.log("firestore sync result", result);
    } catch (error) {
      console.error("Error in debugSaga.", error);
    }
  }
}

export default function* firestoreRootSaga() {
  try {
    yield fork(debugSaga);
  } catch (error) {
    console.error("Error in firestoreRootSaga.", error);
  }
}

import React from "react";

import { Col } from "../../util/styledBootstrapGridWrapper";
import { SingleRow } from "../Layout";
import { H2 } from "../Typography";

const NotFound = () => (
  <SingleRow>
    <Col>
      <H2>{"Page not found!"}</H2>
    </Col>
  </SingleRow>
);
NotFound.displayName = "NotFound";

export default NotFound;

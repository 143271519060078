import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import * as serviceWorker from "./serviceWorker";
import store from "./store";
import theme from "./theme";
import urls from "./urls";
import DefaultLayout from "./ui/pages/DefaultLayout";
import NotFound from "./ui/pages/NotFound";
import Profile from "./ui/pages/Profile";
import SignIn from "./ui/pages/SignIn";
import ContactPage from "./ui/ContactPage";
import Frontpage from "./ui/Frontpage";
import "./util/polyfills";

// Let's not import the .scss file because the image snapshot tests use the file
// If we used scss here, the tests would not be out of sync with the code.
import "./styles.scss";

// Lazy load major components
const FretboardDrillContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "drills-legacy" */ "./drills/fretboard/FretboardDrillContainer"
    )
);
const ChordProgressionDrill = React.lazy(
  () =>
    import(
      /* webpackChunkName: "drills" */ "./drills/chord-progression/ChordProgressionDrill"
    )
);
const ContextualDrill = React.lazy(
  () =>
    import(
      /* webpackChunkName: "drills" */ "./drills/contextual/ContextualDrill"
    )
);

const ChordConstructionDrill = React.lazy(
  () =>
    import(
      /* webpackChunkName: "drills" */ "./drills/chord-construction/ChordConstructionDrill"
    )
);

const IntervalTrainingDrill = React.lazy(
  () =>
    import(
      /* webpackChunkName: "drills" */ "./drills/interval-training/IntervalTrainingDrill"
    )
);

const FretboardIntervalDrill = React.lazy(
  () =>
    import(
      /* webpackChunkName: "drills" */ "./drills/fretboard-interval/FretboardIntervalDrill"
    )
);

/**
 * Remove the loading animation from DOM. The animation is defined in
 * `public/index.html`
 */
const AnimationRemover = () => {
  useEffect(() => {
    if (typeof document !== undefined) {
      const element = document.getElementById("loading");
      if (element) {
        element.outerHTML = "";
      }
    }
  }, []);

  return null;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AnimationRemover />
        <React.Suspense fallback={null}>
          <BrowserRouter>
            <Switch>
              <DefaultLayout
                exact
                path={urls.frontpage}
                component={Frontpage}
                title=""
              />
              <DefaultLayout
                path={urls.chordProgressionDrill}
                component={ChordProgressionDrill}
                title="Chord Progression Ear Training"
              />
              <DefaultLayout
                path={urls.contextual}
                component={ContextualDrill}
                title="Contextual Ear Training"
              />
              <DefaultLayout
                path={urls.chordConstruction}
                component={ChordConstructionDrill}
                title="Chord Construction Training"
              />
              <DefaultLayout
                path={urls.intervalTraining}
                component={IntervalTrainingDrill}
                title="Interval Training"
              />
              <DefaultLayout
                path={urls.fretboardTraining}
                component={FretboardDrillContainer}
                title="Guitar Fretboard Notes"
              />
              <DefaultLayout
                path={urls.fretboardInterval}
                component={FretboardIntervalDrill}
                title="Guitar Fretboard Intervals"
              />
              <DefaultLayout
                path={urls.profile}
                component={Profile}
                title="Profile"
              />
              <DefaultLayout
                path={urls.signIn}
                component={SignIn}
                title="Sign in"
              />
              <DefaultLayout
                path={urls.contact}
                component={ContactPage}
                title="Contact"
              />
              <DefaultLayout component={NotFound} title="Page Not Found" />
            </Switch>
          </BrowserRouter>
        </React.Suspense>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("musicdrill-root")
);

serviceWorker.register();

import Immutable from "immutable";

export const NOTES_ON_FRETBOARD = Immutable.fromJS({
  exerciseId: "notesOnFretboard",
  version: 1,
  list: [
    { string: 6, fret: 0, answer: "E" },
    { string: 6, fret: 1, answer: "F" },
    { string: 5, fret: 0, answer: "A" },
    { string: 6, fret: 3, answer: "G" },
    { string: 5, fret: 2, answer: "B" },
    { string: 4, fret: 0, answer: "D" },
    { string: 6, fret: 5, answer: "A" },
    { string: 5, fret: 3, answer: "C" },
    { string: 4, fret: 2, answer: "E" },
    { string: 3, fret: 0, answer: "G" },
    { string: 6, fret: 7, answer: "B" },
    { string: 5, fret: 5, answer: "D" },
    { string: 4, fret: 3, answer: "F" },
    { string: 3, fret: 2, answer: "A" },
    { string: 2, fret: 0, answer: "B" },
    { string: 6, fret: 8, answer: "C" },
    { string: 5, fret: 7, answer: "E" },
    { string: 4, fret: 5, answer: "G" },
    { string: 3, fret: 4, answer: "B" },
    { string: 2, fret: 1, answer: "C" },
    { string: 1, fret: 0, answer: "E" },
    { string: 6, fret: 10, answer: "D" },
    { string: 5, fret: 8, answer: "F" },
    { string: 6, fret: 12, answer: "E" },
    { string: 5, fret: 10, answer: "G" },
    { string: 4, fret: 7, answer: "A" },
    { string: 3, fret: 5, answer: "C" },
    { string: 2, fret: 3, answer: "D" },
    { string: 1, fret: 1, answer: "F" },
    { string: 5, fret: 12, answer: "A" },
    { string: 4, fret: 9, answer: "B" },
    { string: 3, fret: 7, answer: "D" },
    { string: 2, fret: 5, answer: "E" },
    { string: 1, fret: 3, answer: "G" },
    { string: 4, fret: 10, answer: "C" },
    { string: 3, fret: 9, answer: "E" },
    { string: 2, fret: 6, answer: "F" },
    { string: 1, fret: 5, answer: "A" },
    { string: 4, fret: 12, answer: "D" },
    { string: 3, fret: 10, answer: "F" },
    { string: 2, fret: 8, answer: "G" },
    { string: 1, fret: 7, answer: "B" },
    { string: 3, fret: 12, answer: "G" },
    { string: 2, fret: 10, answer: "A" },
    { string: 1, fret: 8, answer: "C" },
    { string: 2, fret: 12, answer: "B" },
    { string: 1, fret: 10, answer: "D" },
    { string: 1, fret: 12, answer: "E" },
  ].map((question, index) =>
    Object.assign(question, {
      id: index,
    })
  ),
});

// TODO Should this helper function be somewhere in ./memo/
export const mergeQuestionsAndAnswerStats = function(questions, answerStats) {
  return questions.mergeWith((questionsValue, answerStatsValue, key) => {
    if (key !== "list") {
      return typeof answerStatsValue !== undefined
        ? answerStatsValue
        : questionsValue;
    } else {
      // merge questions list with answerStats list
      return answerStatsValue.reduce((accumulator, answer) => {
        const answerId = answer.get("id");
        const index = accumulator.findIndex(q => q.get("id") === answerId);
        if (index !== -1) {
          return accumulator.update(index, q => q.merge(answer));
        } else {
          console.warn(
            "We have answer stats but could not find a matching question",
            answer
          );
          return accumulator;
        }
      }, questionsValue);
    }
  }, answerStats);
};

export const checkAnswer = function(question, answer) {
  return question.get("answer") === answer;
};

const Questions = {
  NOTES_ON_FRETBOARD,
  mergeQuestionsAndAnswerStats,
  checkAnswer,
};

export default Questions;

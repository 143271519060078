import React from "react";
import PropTypes from "prop-types";

import LoginMenu from "./LoginMenu";
import { login } from "../sagas/actions/loginActions";
import connectImmutablePureComponent from "../util/connectImmutablePureComponent";

// TODO refactor into a stateless component
export class LoginMenuContainer extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    login: PropTypes.func.isRequired, // TODO rename to googleLogin
  };

  static defaultProps = {
    user: null,
  };

  constructor() {
    super();
    this.getInitials = this.getInitials.bind(this);
  }

  // TODO move to src/util/
  getInitials() {
    const { user } = this.props;
    if (user !== null) {
      const displayName =
        user.displayName !== null
          ? user.displayName
          : (() => {
              const firstProviderDataWithDisplayName = user.providerData.find(
                ({ displayName }) => displayName !== null
              );
              if (firstProviderDataWithDisplayName) {
                return firstProviderDataWithDisplayName.displayName;
              }
            })();
      if (!displayName) {
        return "";
      }
      const nameParts = displayName.split(" ");
      // TODO handle von, van, de, etc.
      if (nameParts.length === 2) {
        return nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase();
      } else {
        return nameParts[0][0].toUpperCase();
      }
    }
  }

  render() {
    const { user, login } = this.props;

    let signInStatus;
    if (this.props.loading) {
      signInStatus = "unknown";
    } else {
      if (user === null) {
        signInStatus = "loggedOut";
      } else {
        signInStatus = "loggedIn";
      }
    }

    const initials = this.getInitials();
    const displayName = (user && user.displayName) || "";
    const googleLogin = login;

    return (
      <LoginMenu
        signInStatus={signInStatus}
        initials={initials}
        displayName={displayName}
        googleLogin={googleLogin}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.getIn(["login", "loading"]),
  //loggedIn: state.getIn(["login", "loggedIn"]),
  user: state.getIn(["login", "user"]),
});

const mapDispatchToProps = {
  login,
  //logout,
};

export default connectImmutablePureComponent(
  mapStateToProps,
  mapDispatchToProps
)(LoginMenuContainer);
